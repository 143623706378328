const Social = () => {
    return (
        <div className="social">
            <h2>Reach Out</h2>

            <a href="" className="button">officialrachanaranade@gmail.com</a>
        </div>
    );
}

export default Social;